





























































































































































































































































































































































































































































































































































































































































































































































































































































































































































import dayjs from 'dayjs'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import {
  required,
  maxLength,
  requiredIf,
  minLength,
  and,
  minValue,
  ValidationRule,
} from 'vuelidate/lib/validators'
import controller from '@/app/ui/controllers/ProgrammaticVoucherController'
import routeController, { Data } from '@/app/ui/controllers/RouteController'
import Button from '@/app/ui/components/Button/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import OptionBox from '@/app/ui/components/OptionBox/index.vue'
import VoucherTextInput from '../../components/VoucherTextInput/index.vue'
import VoucherDropdown from '../../components/VoucherDropdown/index.vue'
import VoucherDatePicker from '../../components/VoucherDatePicker/index.vue'
import { semanticVersioning } from '@/app/infrastructures/misc/ValidationRules'
import Toggle from '@/app/ui/components/Toggle/index.vue'
import * as constantData from '@/app/infrastructures/misc/Constants/programaticVoucher'
import { DropDownLabelValue } from '@/app/ui/components/DropdownSelect/interface'

interface validationInterface {
  form: {
    voucherName: {
      required: () => ValidationRule
      minLength: ValidationRule
      maxLength: ValidationRule
    }
    voucherTarget: {
      required: () => ValidationRule
    }
    selectedUserFile: {
      name: {
        requiredIf: ValidationRule
      }
    }
    os: {
      requiredIf: ValidationRule
    }
    osValidation: {
      requiredIf: ValidationRule
    }
    osValidationVersion: {
      requiredIf: ValidationRule
    }
    routeIds: {
      requiredIf: ValidationRule
    }
    membershipLevel: {
      requiredIf: ValidationRule
    }
    voucherTrigger: {
      required: () => ValidationRule
    }
    scheduleDate: {
      requiredIf: ValidationRule
    }
    scheduleTime: {
      requiredIf: ValidationRule
    }
    membershipLevelUp: {
      requiredIf: ValidationRule
    }
    limitPerDay: {
      requiredIf: ValidationRule
      minValue: ValidationRule | boolean
    }
    triggerInvoiceType: {
      requiredIf: ValidationRule
    }
    voucherPurposeParent: {
      required: () => ValidationRule
    }
    voucherPurposeChild: {
      required: () => ValidationRule
    }
    pointAmount: {
      requiredIf: ValidationRule
      minValue: ValidationRule | boolean
    }
    discountAmount: {
      requiredIf: ValidationRule
      minValue: ValidationRule | boolean
    }
    discountMinimum: {
      requiredIf: ValidationRule
      minValue: ValidationRule | boolean
    }
    discountPercentage: {
      requiredIf: ValidationRule
      minValue: ValidationRule | boolean
    }
    discountPercentageMinimum: {
      requiredIf: ValidationRule
      minValue: ValidationRule | boolean
    }
    discountPercentageMaximum: {
      requiredIf: ValidationRule
      minValue: ValidationRule | boolean
    }
    cashbackPercentage: {
      requiredIf: ValidationRule
      minValue: ValidationRule | boolean
    }
    cashbackPercentageMinimum: {
      requiredIf: ValidationRule
      minValue: ValidationRule | boolean
    }
    cashbackPercentageMaximum: {
      requiredIf: ValidationRule
      minValue: ValidationRule | boolean
    }
    voucherPurposeInvoiceType: {
      requiredIf: ValidationRule
    }
    limitUsage: {
      requiredIf: ValidationRule
      minValue: ValidationRule | boolean
    }
    startDate: {
      requiredIf: ValidationRule
    }
    startTime: {
      requiredIf: ValidationRule
    }
    endDate: {
      requiredIf: ValidationRule
    }
    endTime: {
      requiredIf: ValidationRule
    }
    budgetAmount: {
      requiredIf: ValidationRule
      minValue: ValidationRule | boolean
    }
  }
}

@Component({
  mixins: [validationMixin],
  components: {
    Button,
    Modal,
    VoucherTextInput,
    VoucherDropdown,
    VoucherDatePicker,
    OptionBox,
    LoadingOverlay,
    Toggle,
  },
})
export default class CreateProgrammaticVoucher extends Vue {
  controller = controller
  routeController = routeController
  constants = constantData
  maxCount = 30
  dateKeys = [
    'scheduleDate',
    'scheduleTime',
    'startDate',
    'startDate',
    'endDate',
    'endTime',
  ]
  form = {
    voucherName: '',
    voucherTarget: <DropDownLabelValue<string>>(<unknown>[]),
    selectedUserFile: new File([], ''),
    os: <DropDownLabelValue<string>>(<unknown>[]),
    osValidation: <DropDownLabelValue<string>>(<unknown>[]),
    osValidationVersion: '',
    routeIds: <Record<string, string>[]>[],
    membershipLevel: <DropDownLabelValue<string>>(<unknown>[]),
    voucherTrigger: <DropDownLabelValue<string>>(<unknown>[]),
    scheduleDate: '',
    scheduleTime: '',
    membershipLevelUp: <DropDownLabelValue<string>>(<unknown>[]),
    limitPerDay: 1,
    triggerInvoiceType: <string[]>[],
    voucherPurposeParent: <DropDownLabelValue<string>>(<unknown>[]),
    voucherPurposeChild: <DropDownLabelValue<string>>(<unknown>[]),
    pointAmount: 0,
    pointExpiry: 1,
    pointPurpose: <string[]>[],
    discountAmount: 0,
    discountMinimum: 0,
    discountPercentage: 0,
    discountPercentageMinimum: 0,
    discountPercentageMaximum: 0,
    cashbackPercentage: 0,
    cashbackPercentageMinimum: 0,
    cashbackPercentageMaximum: 0,
    voucherPurposeInvoiceType: <string[]>[],
    voucherPurposeProductType: <string[]>[],
    voucherExpiryDate: '',
    limitUsage: 1,
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    budgetAmount: <null | number | undefined>null,
  }
  toggleBudgetAmount = false
  successModal = false
  confirmationModal = false
  todayDate = dayjs().format('YYYY-MM-DD')
  hasChanged = {
    voucherName: false,
    voucherTarget: false,
    selectedUserFile: false,
    os: false,
    osValidation: false,
    osValidationVersion: false,
    routeIds: false,
    membershipLevel: false,
    voucherTrigger: false,
    scheduleDate: false,
    scheduleTime: false,
    membershipLevelUp: false,
    limitPerDay: false,
    triggerInvoiceType: false,
    voucherPurposeParent: false,
    voucherPurposeChild: false,
    pointAmount: false,
    pointExpiry: false,
    pointPurpose: false,
    discountAmount: false,
    discountMinimum: false,
    discountPercentage: false,
    discountPercentageMinimum: false,
    discountPercentageMaximum: false,
    cashbackPercentage: false,
    cashbackPercentageMinimum: false,
    cashbackPercentageMaximum: false,
    voucherPurposeInvoiceType: false,
    voucherPurposeProductType: false,
    voucherExpiryDate: false,
    limitUsage: false,
    startDate: false,
    startTime: false,
    endDate: false,
    endTime: false,
    budgetAmount: false,
  }
  fileAttachmentName: string | null = null
  productTypeOptions = this.constants.PRODUCT_TYPE

  created(): void {
    controller.getTriggers()
  }

  @Validations()
  validations(): validationInterface {
    const validate = {
      form: {
        voucherName: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(30),
        },
        voucherTarget: { required },
        selectedUserFile: {
          name: {
            requiredIf: requiredIf(() => this.isSelectedUserTarget),
          },
        },
        os: {
          requiredIf: requiredIf(() => this.isOSTarget),
        },
        osValidation: {
          requiredIf: requiredIf(() => this.isOSTarget),
        },
        osValidationVersion: {
          requiredIf: and(
            requiredIf(() => this.isOSTarget),
            semanticVersioning
          ),
        },
        routeIds: {
          requiredIf: requiredIf(() => this.isOriginTarget),
        },
        membershipLevel: {
          requiredIf: requiredIf(() => this.isMembershipTarget),
        },
        voucherTrigger: { required },
        scheduleDate: {
          requiredIf: requiredIf(() => this.isOnSchedule),
        },
        scheduleTime: {
          requiredIf: requiredIf(() => this.isOnSchedule),
        },
        membershipLevelUp: {
          requiredIf: requiredIf(() => this.isMembershipLevelUpTrigger),
        },
        limitPerDay: {
          requiredIf: requiredIf(() => !this.isOnSchedule),
          minValue: !this.isOnSchedule ? minValue(1) : true,
        },
        triggerInvoiceType: {
          requiredIf: and(
            requiredIf(() => this.isInvoicePaidTrigger),
            (value: Array<unknown>) =>
              this.isInvoicePaidTrigger ? value.length > 0 : true
          ),
        },
        voucherPurposeParent: { required },
        voucherPurposeChild: { required },
        pointAmount: {
          requiredIf: requiredIf(() => this.isRedeemToPoint),
          minValue: this.isRedeemToPoint ? minValue(1) : true,
        },
        discountAmount: {
          requiredIf: requiredIf(() => this.isDiscByAmount),
          minValue: this.isDiscByAmount ? minValue(1) : true,
        },
        discountMinimum: {
          requiredIf: requiredIf(() => this.isDiscByAmount),
          minValue: this.isDiscByAmount ? minValue(1) : true,
        },
        discountPercentage: {
          requiredIf: requiredIf(() => this.isDiscByPercent),
          minValue: this.isDiscByPercent ? minValue(1) : true,
        },
        discountPercentageMinimum: {
          requiredIf: requiredIf(() => this.isDiscByPercent),
          minValue: this.isDiscByPercent ? minValue(1) : true,
        },
        discountPercentageMaximum: {
          requiredIf: requiredIf(() => this.isDiscByPercent),
          minValue: this.isDiscByPercent ? minValue(1) : true,
        },
        cashbackPercentage: {
          requiredIf: requiredIf(() => this.isCashback),
          minValue: this.isCashback ? minValue(1) : true,
        },
        cashbackPercentageMinimum: {
          requiredIf: requiredIf(() => this.isCashback),
          minValue: this.isCashback ? minValue(1) : true,
        },
        cashbackPercentageMaximum: {
          requiredIf: requiredIf(() => this.isCashback),
          minValue: this.isCashback ? minValue(1) : true,
        },
        voucherPurposeInvoiceType: {
          requiredIf: and(
            requiredIf(() => !this.isRedeemToPoint),
            (value: Array<unknown>) =>
              !this.isRedeemToPoint ? value.length > 0 : true
          ),
        },
        voucherExpiryDate: { required },
        limitUsage: {
          requiredIf: requiredIf(() => !this.isRedeemToPoint),
          minValue: !this.isRedeemToPoint ? minValue(1) : true,
        },
        startDate: {
          requiredIf: requiredIf(() => !this.isOnSchedule),
        },
        startTime: {
          requiredIf: requiredIf(() => !this.isOnSchedule),
        },
        endDate: {
          requiredIf: requiredIf(() => !this.isOnSchedule),
        },
        endTime: {
          requiredIf: requiredIf(() => !this.isOnSchedule),
        },
        budgetAmount: {
          requiredIf: requiredIf(() => this.toggleBudgetAmount),
          minValue: this.toggleBudgetAmount
            ? minValue(this.validateBudgetAmount)
            : true,
        },
      },
    }

    return validate
  }

  get validateBudgetAmount(): number {
    let givenNum = 1
    if (this.isDiscByAmount) {
      givenNum = this.form.discountAmount
    }
    if (this.isDiscByPercent) {
      givenNum = this.form.discountPercentageMaximum
    }
    if (this.isCashback) {
      givenNum = this.form.cashbackPercentageMaximum
    }
    if (this.isRedeemToPoint) {
      givenNum = this.form.pointAmount
    }
    return givenNum
  }

  get errorMessageBudgetAmount(): string | undefined {
    if (
      !this.$v.form.budgetAmount?.requiredIf &&
      this.hasChanged.budgetAmount
    ) {
      return 'Budget amount can’t be 0'
    } else if (
      !this.$v.form.budgetAmount?.minValue &&
      this.hasChanged.budgetAmount
    ) {
      return 'Budget amount must be higher than the point/discount amount'
    }
    return undefined
  }

  @Watch('form.voucherTarget')
  onTargetUserChanged(val: { label: string; value: string }): void {
    if (val.value === this.constants.TARGET_USERS[3].value) {
      routeController.getCityList()
    }
  }

  @Watch('form.voucherPurposeParent')
  onVoucherPurposeParentChanged(): void {
    this.form.voucherPurposeChild = <DropDownLabelValue<string>>(<unknown>[])
    this.form.pointAmount = 0
    this.form.pointExpiry = 0
    this.form.pointPurpose = <string[]>[]
    this.form.discountAmount = 0
    this.form.discountMinimum = 0
    this.form.discountPercentage = 0
    this.form.discountPercentageMinimum = 0
    this.form.discountPercentageMaximum = 0
    this.form.cashbackPercentage = 0
    this.form.cashbackPercentageMinimum = 0
    this.form.cashbackPercentageMaximum = 0
    this.form.voucherPurposeInvoiceType = <string[]>[]
    this.form.voucherPurposeProductType = <string[]>[]
    this.form.voucherExpiryDate = ''
    this.form.limitUsage = 1
    this.form.startDate = ''
    this.form.startTime = ''
    this.form.endDate = ''
    this.form.endTime = ''
  }

  @Watch('form.voucherTrigger')
  onChange(): void {
    if (!(this.form.voucherTrigger instanceof Array) && this.isOnSchedule) {
      this.toggleBudgetAmount = false
      this.form.budgetAmount = null
    }
  }

  get isSelectedUserTarget(): boolean {
    return this.form.voucherTarget.value === constantData.UserType.SELECTED
  }

  get isOSTarget(): boolean {
    return this.form.voucherTarget.value === constantData.UserType.SPECIFIC_OS
  }

  get isOriginTarget(): boolean {
    return (
      this.form.voucherTarget.value === constantData.UserType.SPECIFIC_ORIGIN
    )
  }

  get isMembershipTarget(): boolean {
    return (
      this.form.voucherTarget.value === constantData.UserType.MEMBERSHIP_LEVEL
    )
  }

  get isOnSchedule(): boolean {
    return (
      this.form.voucherTrigger.value === this.constants.TriggerType.SCHEDULE
    )
  }

  get isMembershipLevelUpTrigger(): boolean {
    return (
      this.form.voucherTrigger.value ===
      this.constants.TriggerType.MEMBERSHIP_LEVEL_UP
    )
  }

  get isInvoicePaidTrigger(): boolean {
    return (
      this.form.voucherTrigger.value === this.constants.TriggerType.INVOICE_PAID
    )
  }

  get isRedeemToPoint(): boolean {
    return (
      this.form.voucherPurposeChild.value ===
      this.constants.TermConditionType.REDEEM_PARCEL_POINT
    )
  }

  get isDiscByAmount(): boolean {
    return (
      this.form.voucherPurposeChild.value ===
      this.constants.TermConditionType.DISCOUNT_BY_AMOUNT
    )
  }

  get isDiscByPercent(): boolean {
    return (
      this.form.voucherPurposeChild.value ===
      this.constants.TermConditionType.DISCOUNT_BY_PERCENTAGE
    )
  }

  get isCashback(): boolean {
    return (
      this.form.voucherPurposeChild.value ===
      this.constants.TermConditionType.CASHBACK
    )
  }

  get triggerOptions(): DropDownLabelValue<string>[] {
    return controller.triggerData.map(trigger => ({
      ...trigger,
      $isDisabled:
        trigger.value === this.constants.TriggerType.MEMBERSHIP_LEVEL_UP,
    }))
  }

  get routeOptions(): Data[] {
    return routeController.cityData
  }

  private optionVoucherPurposes(): DropDownLabelValue<
    constantData.PurposeType
  >[] {
    return constantData.PURPOSE_OPTIONS.filter(
      v => v.value !== constantData.PurposeType.REDEEM_POINT
    )
  }

  get childPurposeOptions(): DropDownLabelValue<
    constantData.TermConditionType
  >[] {
    return this.form.voucherPurposeParent.value ===
      this.constants.TermConditionType.REDEEM_PARCEL_POINT
      ? constantData.TERM_COND_OPTIONS.filter(
          item =>
            item.value === this.constants.TermConditionType.REDEEM_PARCEL_POINT
        )
      : constantData.TERM_COND_OPTIONS.filter(
          item =>
            item.value !== this.constants.TermConditionType.REDEEM_TO_POINT
        )
  }

  private setAllChanged(): void {
    this.hasChanged = {
      voucherName: true,
      voucherTarget: true,
      selectedUserFile: true,
      os: true,
      osValidation: true,
      osValidationVersion: true,
      routeIds: true,
      membershipLevel: true,
      voucherTrigger: true,
      scheduleDate: true,
      scheduleTime: true,
      membershipLevelUp: true,
      limitPerDay: true,
      triggerInvoiceType: true,
      voucherPurposeParent: true,
      voucherPurposeChild: true,
      pointAmount: true,
      pointExpiry: true,
      pointPurpose: true,
      discountAmount: true,
      discountMinimum: true,
      discountPercentage: true,
      discountPercentageMinimum: true,
      discountPercentageMaximum: true,
      cashbackPercentage: true,
      cashbackPercentageMinimum: true,
      cashbackPercentageMaximum: true,
      voucherPurposeInvoiceType: true,
      voucherPurposeProductType: true,
      voucherExpiryDate: true,
      limitUsage: true,
      startDate: true,
      startTime: true,
      endDate: true,
      endTime: true,
      budgetAmount: true,
    }
  }

  private setTriggerInvoiceType(value: string): void {
    this.hasChanged.triggerInvoiceType = true
    if (this.form.triggerInvoiceType.includes(value)) {
      this.form.triggerInvoiceType = this.form.triggerInvoiceType.filter(
        item => item !== value
      )
    } else {
      this.form.triggerInvoiceType.push(value)
    }
  }

  public setShipmentType(value: string): void {
    this.hasChanged.voucherPurposeInvoiceType = true
    if (this.form.voucherPurposeInvoiceType.includes(value)) {
      this.form.voucherPurposeInvoiceType = this.form.voucherPurposeInvoiceType.filter(
        item => item !== value
      )
    } else {
      this.form.voucherPurposeInvoiceType.push(value)
    }

    this.handleProductType()
  }

  public setProductType(value: string): void {
    this.hasChanged.voucherPurposeProductType = true
    if (this.form.voucherPurposeProductType.includes(value)) {
      this.form.voucherPurposeProductType = this.form.voucherPurposeProductType.filter(
        item => item !== value
      )
    } else {
      this.form.voucherPurposeProductType.push(value)
    }
  }

  private handleProductType(): void {
    if (
      this.form.voucherPurposeInvoiceType.includes(
        this.constants.ShipmentType.PICKUP
      )
    ) {
      this.productTypeOptions = this.productTypeOptions.map(pt => {
        if (pt.value === 'SAMEDAY') {
          return {
            ...pt,
            disabled: false,
          }
        }
        return pt
      })
    }

    if (
      !this.form.voucherPurposeInvoiceType.includes(
        this.constants.ShipmentType.PICKUP
      )
    ) {
      this.productTypeOptions = this.productTypeOptions.map(pt => {
        return {
          ...pt,
          disabled: true,
        }
      })

      this.form.voucherPurposeProductType = []
    }
  }

  /* TODO: since Redeem To Poin Release, we don't use this for now */
  // private setPointPurpose(value: string): void {
  //   this.hasChanged.pointPurpose = true
  //   if (this.form.pointPurpose.includes(value)) {
  //     this.form.pointPurpose = this.form.pointPurpose.filter(
  //       item => item !== value
  //     )
  //   } else {
  //     this.form.pointPurpose.push(value)
  //   }
  // }

  private onFileChange($event: File): void {
    this.hasChanged.selectedUserFile = true
    this.form.selectedUserFile = $event
    if (this.form.selectedUserFile.name) {
      this.fileAttachmentName = this.form.selectedUserFile.name
    }
  }

  private onCreateVoucher(): void {
    this.setAllChanged()
    this.confirmationModal = false
    if (!this.$v.form.$invalid) {
      controller.createProgrammaticVoucher(this.form)
    } else {
      this.$notify({
        title: 'Invalid Form Program Voucher',
        text: 'Please check every invalid form',
        type: 'error',
        duration: 5000,
      })
    }
  }

  @Watch('controller.isSuccessCreate')
  onSuccessCreate(isSuccess: boolean): void {
    if (isSuccess) {
      this.successModal = true
    }
  }

  private onCloseSuccessModal(): void {
    this.successModal = false
    this.$router.push({ name: 'ProgrammaticVoucherPage' })
  }

  private isToggleBudgetAmount(): void {
    this.toggleBudgetAmount = !this.toggleBudgetAmount
    this.form.budgetAmount = null
  }
}
