var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"create-voucher-wrapper"},[(_vm.controller.isLoading)?_c('LoadingOverlay'):_vm._e(),_c('Modal',{ref:"model-confirm-create",attrs:{"visible":_vm.confirmationModal,"title":"Create New Voucher?"}},[_c('div',{staticClass:"text-center",attrs:{"slot":"modal-body"},slot:"modal-body"},[_c('p',[_vm._v("Are you sure want to create new voucher :")]),_c('p',{staticClass:"font-semibold"},[_vm._v("\""+_vm._s(_vm.form.voucherName)+"\"?")])]),_c('div',{attrs:{"slot":"modal-footer"},slot:"modal-footer"},[_c('Button',{staticClass:"mr-3",attrs:{"buttonStyle":"outline"},on:{"click":function () {
            _vm.confirmationModal = false
          }}},[_vm._v("Check Again ")]),_c('Button',{ref:"button-create",on:{"click":function () {
            _vm.onCreateVoucher()
          }}},[_vm._v("Yes, Create ")])],1)]),_c('Modal',{ref:"model-success",attrs:{"visible":_vm.successModal,"title":"Voucher Created","type":"success","body":"Your Voucher has been created and add to the list"},on:{"change":_vm.onCloseSuccessModal}}),_c('h2',{staticClass:"text-xl font-semibold mb-7 text-black-2"},[_vm._v(" Create New Voucher Program ")]),_c('div',{staticClass:"flex flex-row justify-between"},[_c('div',{staticClass:"flex flex-col w-2/5"},[_c('VoucherTextInput',{attrs:{"fieldLabel":"Program Voucher Name","required":true,"fieldDescription":"Min 3 characters","withCounter":true,"maxCount":_vm.maxCount,"id":"voucher-name","name":"voucherName","type":"text","placeholder":"Type Voucher Name","errorMessage":!_vm.$v.form.voucherName.required && _vm.hasChanged.voucherName
            ? 'field is required'
            : undefined},on:{"input":function () {
            _vm.hasChanged.voucherName = true
          }},model:{value:(_vm.form.voucherName),callback:function ($$v) {_vm.$set(_vm.form, "voucherName", $$v)},expression:"form.voucherName"}}),_c('div',[_c('h3',{staticClass:"text-grayInactive text-base font-semibold mb-4"},[_vm._v(" Target User ")]),_c('VoucherDropdown',{attrs:{"fieldLabel":"User Type","required":true,"id":"voucher-target-user","name":"voucherTarget","placeholder":"Select User Type","clearOnSelect":false,"allowEmpty":false,"options":_vm.constants.TARGET_USERS,"errorMessage":!_vm.$v.form.voucherTarget.required && _vm.hasChanged.voucherTarget
              ? 'field is required'
              : undefined},on:{"input":function () {
              _vm.hasChanged.voucherTarget = true
            }},model:{value:(_vm.form.voucherTarget),callback:function ($$v) {_vm.$set(_vm.form, "voucherTarget", $$v)},expression:"form.voucherTarget"}}),(_vm.isSelectedUserTarget)?_c('VoucherTextInput',{ref:"input-selected-users",attrs:{"fieldLabel":"List of User","required":true,"accept":"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel","fieldDescription":"Max size : 5 Mb, Format : Excel","id":"voucher-user-list-file","name":"selectedUserFile","type":"file","placeholder":"Attach file","fileName":_vm.fileAttachmentName,"errorMessage":!_vm.$v.form.selectedUserFile.name.requiredIf &&
            _vm.hasChanged.selectedUserFile
              ? 'field is required'
              : undefined},on:{"change":_vm.onFileChange}}):_vm._e(),(_vm.isOSTarget)?_c('div',{staticClass:"flex items-end flex-row md:flex-wrap lg:flex-nowrap gap-4"},[_c('VoucherDropdown',{staticClass:"w-40",attrs:{"fieldLabel":"User OS","required":"","id":"voucher-user-os","name":"os","placeholder":"Select the OS","clearOnSelect":false,"allowEmpty":false,"options":_vm.constants.PLATFORM_OS_OPTIONS,"errorMessage":!_vm.$v.form.os.requiredIf && _vm.hasChanged.os
                ? 'field is required'
                : undefined},on:{"input":function () {
                _vm.hasChanged.os = true
              }},model:{value:(_vm.form.os),callback:function ($$v) {_vm.$set(_vm.form, "os", $$v)},expression:"form.os"}}),_c('VoucherDropdown',{attrs:{"fieldLabel":"Version App","required":true,"id":"voucher-os-measurement","name":"osValidation","placeholder":"Select measurement","clearOnSelect":false,"allowEmpty":false,"options":_vm.constants.MEASUREMENT_OPTIONS,"errorMessage":!_vm.$v.form.osValidation.requiredIf && _vm.hasChanged.osValidation
                ? 'field is required'
                : undefined},on:{"input":function () {
                _vm.hasChanged.osValidation = true
              }},model:{value:(_vm.form.osValidation),callback:function ($$v) {_vm.$set(_vm.form, "osValidation", $$v)},expression:"form.osValidation"}}),_c('VoucherTextInput',{style:(!_vm.$v.form.osValidationVersion.requiredIf &&
              _vm.hasChanged.osValidationVersion
                ? 'margin-bottom: 0'
                : ''),attrs:{"fieldLabel":"","id":"voucher-version-number","name":"osValidationVersion","type":"text","placeholder":"Version number","errorMessage":!_vm.$v.form.osValidationVersion.requiredIf &&
              _vm.hasChanged.osValidationVersion
                ? 'field is required'
                : undefined},on:{"input":function () {
                _vm.hasChanged.osValidationVersion = true
              }},model:{value:(_vm.form.osValidationVersion),callback:function ($$v) {_vm.$set(_vm.form, "osValidationVersion", $$v)},expression:"form.osValidationVersion"}})],1):_vm._e(),(_vm.isOriginTarget)?_c('VoucherDropdown',{attrs:{"multiple":true,"required":"","loading":_vm.controller.isLoadingRoute,"fieldLabel":"Select city","id":"route-ids","name":"routeIds","placeholder":"Name of city","options":_vm.routeOptions,"errorMessage":!_vm.$v.form.routeIds.requiredIf && _vm.hasChanged.routeIds
              ? 'field is required'
              : undefined},on:{"input":function () {
              _vm.hasChanged.routeIds = true
            }},model:{value:(_vm.form.routeIds),callback:function ($$v) {_vm.$set(_vm.form, "routeIds", $$v)},expression:"form.routeIds"}}):_vm._e(),(_vm.isMembershipTarget)?_c('VoucherDropdown',{attrs:{"fieldLabel":"Membership Level","required":true,"id":"voucher-membership-level","name":"membershipLevel","placeholder":"Select Membership Level","clearOnSelect":false,"allowEmpty":false,"options":_vm.constants.MEMBERSHIP_OPTIONS,"errorMessage":!_vm.$v.form.membershipLevel.requiredIf && _vm.hasChanged.membershipLevel
              ? 'field is required'
              : undefined},on:{"input":function () {
              _vm.hasChanged.membershipLevel = true
            }},model:{value:(_vm.form.membershipLevel),callback:function ($$v) {_vm.$set(_vm.form, "membershipLevel", $$v)},expression:"form.membershipLevel"}}):_vm._e(),_c('h3',{staticClass:"text-grayInactive text-base font-semibold mt-8 mb-4"},[_vm._v(" Voucher Trigger By ")]),_c('VoucherDropdown',{attrs:{"loading":_vm.controller.isLoadingTrigger,"isFailedLoadData":!_vm.controller.isLoadingTrigger && _vm.triggerOptions.length <= 0,"fieldLabel":"Select Voucher Trigger","required":true,"id":"voucher-trigger","name":"voucherTrigger","placeholder":"Select Voucher Trigger","clearOnSelect":false,"allowEmpty":false,"options":_vm.triggerOptions,"errorMessage":!_vm.$v.form.voucherTrigger.required && _vm.hasChanged.voucherTrigger
              ? 'field is required'
              : undefined},on:{"onreload":function () {
              _vm.controller.getTriggers()
            },"input":function () {
              _vm.hasChanged.voucherTrigger = true
            }},model:{value:(_vm.form.voucherTrigger),callback:function ($$v) {_vm.$set(_vm.form, "voucherTrigger", $$v)},expression:"form.voucherTrigger"}}),(_vm.form.voucherTrigger && _vm.isOnSchedule)?_c('div',{staticClass:"flex flex-row"},[_c('VoucherDatePicker',{staticClass:"w-1/2 mr-2",attrs:{"min-date":_vm.todayDate,"valueFormat":"date","formatted":"DD/MM/YYYY","fieldLabel":"Schedule Date","required":true,"id":"voucher-schedule-date","name":"voucherScheduleDate","label":"DD/MM/YYYY","auto-close":true,"errorMessage":!_vm.$v.form.scheduleDate.requiredIf && _vm.hasChanged.scheduleDate
                ? 'field is required'
                : undefined},on:{"input":function () {
                _vm.hasChanged.scheduleDate = true
              }},model:{value:(_vm.form.scheduleDate),callback:function ($$v) {_vm.$set(_vm.form, "scheduleDate", $$v)},expression:"form.scheduleDate"}}),_c('VoucherDatePicker',{staticClass:"w-1/2",attrs:{"valueFormat":"time","formatted":"HH:mm:ss","fieldLabel":"Schedule Time","required":true,"id":"voucher-schedule-time","name":"voucherScheduleTime","label":"HH:MM:SS","errorMessage":!_vm.$v.form.scheduleTime.requiredIf && _vm.hasChanged.scheduleTime
                ? 'field is required'
                : undefined},on:{"input":function () {
                _vm.hasChanged.scheduleTime = true
              }},model:{value:(_vm.form.scheduleTime),callback:function ($$v) {_vm.$set(_vm.form, "scheduleTime", $$v)},expression:"form.scheduleTime"}})],1):_vm._e(),(_vm.form.voucherTrigger && _vm.isMembershipLevelUpTrigger)?_c('VoucherDropdown',{attrs:{"fieldLabel":"Select Level Up","required":true,"id":"membership-level-up","name":"membershipLevelUp","placeholder":"Select Voucher Trigger","clearOnSelect":false,"allowEmpty":false,"options":_vm.constants.LEVEL_UP_OPTIONS,"errorMessage":!_vm.$v.form.membershipLevelUp.requiredIf &&
            _vm.hasChanged.membershipLevelUp
              ? 'field is required'
              : undefined},on:{"input":function () {
              _vm.hasChanged.membershipLevelUp = true
            }},model:{value:(_vm.form.membershipLevelUp),callback:function ($$v) {_vm.$set(_vm.form, "membershipLevelUp", $$v)},expression:"form.membershipLevelUp"}}):_vm._e(),(!(_vm.form.voucherTrigger instanceof Array) && !_vm.isOnSchedule)?_c('VoucherTextInput',{attrs:{"fieldLabel":"Limit voucher in a day","required":true,"fieldSuffix":"usage","id":"limit-per-day","name":"limitPerDay","type":"number","placeholder":"Input number","errorMessage":_vm.$v.form.limitPerDay.$invalid && _vm.hasChanged.limitPerDay
              ? 'field minimal value is 1'
              : undefined},on:{"input":function () {
              _vm.hasChanged.limitPerDay = true
            }},model:{value:(_vm.form.limitPerDay),callback:function ($$v) {_vm.$set(_vm.form, "limitPerDay", $$v)},expression:"form.limitPerDay"}}):_vm._e(),(_vm.form.voucherTrigger && _vm.isInvoicePaidTrigger)?_c('div',{staticClass:"flex flex-col"},[_c('OptionBox',{ref:"select-invoice",attrs:{"fieldLabel":"Type of Invoice","required":true,"fieldDescription":"You can select more than 1","errorMessage":!_vm.$v.form.triggerInvoiceType.requiredIf &&
              _vm.hasChanged.triggerInvoiceType
                ? 'At least select one'
                : undefined,"id":"trigger-invoice-type","name":"triggerInvoiceType","preselected":_vm.form.triggerInvoiceType,"options":_vm.constants.POINT_PURPOSES},on:{"click":_vm.setTriggerInvoiceType}})],1):_vm._e(),_c('h3',{staticClass:"text-grayInactive text-base font-semibold mt-8 mb-4"},[_vm._v(" Voucher Purpose ")]),_c('VoucherDropdown',{attrs:{"fieldLabel":"Select Voucher Purpose","required":true,"id":"voucher-purpose-parent","name":"voucherPurposeParent","placeholder":"Select Voucher Purpose","clearOnSelect":false,"allowEmpty":false,"options":_vm.optionVoucherPurposes(),"errorMessage":!_vm.$v.form.voucherPurposeParent.required &&
            _vm.hasChanged.voucherPurposeParent
              ? 'field is required'
              : undefined},on:{"input":function () {
              _vm.hasChanged.voucherPurposeParent = true
            }},model:{value:(_vm.form.voucherPurposeParent),callback:function ($$v) {_vm.$set(_vm.form, "voucherPurposeParent", $$v)},expression:"form.voucherPurposeParent"}}),_c('h3',{staticClass:"text-grayInactive text-base font-semibold mt-8 mb-4"},[_vm._v(" Terms & Conditions ")]),_c('VoucherDropdown',{attrs:{"disabled":!_vm.hasChanged.voucherPurposeParent,"fieldLabel":"Select Terms & Conditions","required":true,"id":"voucher-purpose-child","name":"voucherPurposeChild","placeholder":"Select Terms & Conditions","clearOnSelect":false,"allowEmpty":false,"options":_vm.childPurposeOptions,"errorMessage":!_vm.$v.form.voucherPurposeChild.required &&
            _vm.hasChanged.voucherPurposeChild
              ? 'field is required'
              : undefined},on:{"input":function () {
              _vm.hasChanged.voucherPurposeChild = true
            }},model:{value:(_vm.form.voucherPurposeChild),callback:function ($$v) {_vm.$set(_vm.form, "voucherPurposeChild", $$v)},expression:"form.voucherPurposeChild"}}),(!(_vm.form.voucherPurposeChild instanceof Array))?[(_vm.isRedeemToPoint)?_c('div',{staticClass:"flex flex-col"},[_c('VoucherTextInput',{attrs:{"fieldLabel":"Parcel Poin Amount","required":true,"id":"point-amount","name":"pointAmount","type":"currency","placeholder":"Input number","errorMessage":!_vm.$v.form.pointAmount.requiredIf && _vm.hasChanged.pointAmount
                  ? 'field is required'
                  : !_vm.$v.form.pointAmount.minValue && _vm.hasChanged.pointAmount
                  ? 'field minimal value is 1'
                  : undefined},on:{"input":function () {
                  _vm.hasChanged.pointAmount = true
                }},model:{value:(_vm.form.pointAmount),callback:function ($$v) {_vm.$set(_vm.form, "pointAmount", $$v)},expression:"form.pointAmount"}})],1):_vm._e(),(_vm.isDiscByAmount)?_c('VoucherTextInput',{attrs:{"fieldLabel":"Discount Amount","required":true,"id":"discount-amount","name":"discountAmount","type":"currency","placeholder":"Input number","errorMessage":!_vm.$v.form.discountAmount.requiredIf && _vm.hasChanged.discountAmount
                ? 'field is required'
                : !_vm.$v.form.discountAmount.minValue &&
                  _vm.hasChanged.discountAmount
                ? 'field minimal value is 1'
                : undefined},on:{"input":function () {
                _vm.hasChanged.discountAmount = true
              }},model:{value:(_vm.form.discountAmount),callback:function ($$v) {_vm.$set(_vm.form, "discountAmount", $$v)},expression:"form.discountAmount"}}):_vm._e(),(_vm.isDiscByPercent)?_c('VoucherTextInput',{attrs:{"fieldLabel":"Discount Percentage","required":true,"fieldSuffix":"%","id":"discount-percentage","name":"discountPercentage","type":"number","placeholder":"Input number","errorMessage":!_vm.$v.form.discountPercentage.requiredIf &&
              _vm.hasChanged.discountPercentage
                ? 'field is required'
                : !_vm.$v.form.discountPercentage.minValue &&
                  _vm.hasChanged.discountPercentage
                ? 'field minimal value is 1'
                : undefined},on:{"input":function () {
                _vm.hasChanged.discountPercentage = true
              }},model:{value:(_vm.form.discountPercentage),callback:function ($$v) {_vm.$set(_vm.form, "discountPercentage", $$v)},expression:"form.discountPercentage"}}):_vm._e(),(_vm.isCashback)?_c('VoucherTextInput',{attrs:{"fieldLabel":"Cashback Percentage","required":true,"fieldSuffix":"%","id":"cashback-percentage","name":"cashbackPercentage","type":"number","placeholder":"Input number","errorMessage":!_vm.$v.form.cashbackPercentage.requiredIf &&
              _vm.hasChanged.cashbackPercentage
                ? 'field is required'
                : !_vm.$v.form.cashbackPercentage.minValue &&
                  _vm.hasChanged.cashbackPercentage
                ? 'field minimal value is 1'
                : undefined},on:{"input":function () {
                _vm.hasChanged.cashbackPercentage = true
              }},model:{value:(_vm.form.cashbackPercentage),callback:function ($$v) {_vm.$set(_vm.form, "cashbackPercentage", $$v)},expression:"form.cashbackPercentage"}}):_vm._e()]:_vm._e()],2),(!(_vm.form.voucherPurposeChild instanceof Array))?[(!_vm.isRedeemToPoint)?_c('div',{staticClass:"flex flex-row"},[_c('div',{staticClass:"flex flex-col w-full"},[_c('div',{staticClass:"flex flex-row w-full"},[(_vm.isDiscByAmount)?_c('VoucherTextInput',{staticClass:"w-1/2 mr-2",attrs:{"fieldLabel":"Minimum Transaction","required":true,"id":"discount-minimum","name":"discountMinimum","type":"currency","placeholder":"Input number","errorMessage":!_vm.$v.form.discountMinimum.requiredIf &&
                  _vm.hasChanged.discountMinimum
                    ? 'field is required'
                    : !_vm.$v.form.discountMinimum.minValue &&
                      _vm.hasChanged.discountMinimum
                    ? 'field minimal value is 1'
                    : undefined},on:{"input":function () {
                    _vm.hasChanged.discountMinimum = true
                  }},model:{value:(_vm.form.discountMinimum),callback:function ($$v) {_vm.$set(_vm.form, "discountMinimum", $$v)},expression:"form.discountMinimum"}}):_vm._e()],1),_c('div',{staticClass:"flex flex-row w-full"},[(_vm.isDiscByPercent)?_c('VoucherTextInput',{staticClass:"w-1/2 mr-2",attrs:{"fieldLabel":"Minimum Transaction","required":true,"id":"discount-percentage-minimum","name":"discountPercentageMinimum","type":"currency","placeholder":"Input number","errorMessage":!_vm.$v.form.discountPercentageMinimum.requiredIf &&
                  _vm.hasChanged.discountPercentageMinimum
                    ? 'field is required'
                    : !_vm.$v.form.discountPercentageMinimum.minValue &&
                      _vm.hasChanged.discountPercentageMinimum
                    ? 'field minimal value is 1'
                    : undefined},on:{"input":function () {
                    _vm.hasChanged.discountPercentageMinimum = true
                  }},model:{value:(_vm.form.discountPercentageMinimum),callback:function ($$v) {_vm.$set(_vm.form, "discountPercentageMinimum", $$v)},expression:"form.discountPercentageMinimum"}}):_vm._e(),(_vm.isDiscByPercent)?_c('VoucherTextInput',{staticClass:"w-1/2",attrs:{"fieldLabel":"Maximum Point Amount","required":true,"id":"discount-percentage-maximum","name":"discountPercentageMaximum","type":"currency","placeholder":"Input number","errorMessage":!_vm.$v.form.discountPercentageMaximum.requiredIf &&
                  _vm.hasChanged.discountPercentageMaximum
                    ? 'field is required'
                    : !_vm.$v.form.discountPercentageMaximum.minValue &&
                      _vm.hasChanged.discountPercentageMaximum
                    ? 'field minimal value is 1'
                    : undefined},on:{"input":function () {
                    _vm.hasChanged.discountPercentageMaximum = true
                  }},model:{value:(_vm.form.discountPercentageMaximum),callback:function ($$v) {_vm.$set(_vm.form, "discountPercentageMaximum", $$v)},expression:"form.discountPercentageMaximum"}}):_vm._e()],1),_c('div',{staticClass:"flex flex-row w-full"},[(_vm.isCashback)?_c('VoucherTextInput',{staticClass:"w-1/2 mr-2",attrs:{"fieldLabel":"Minimum Transaction","required":true,"id":"cashback-percentage-minimum","name":"cashbackPercentageMinimum","type":"currency","placeholder":"Input number","errorMessage":!_vm.$v.form.cashbackPercentageMinimum.requiredIf &&
                  _vm.hasChanged.cashbackPercentageMinimum
                    ? 'field is required'
                    : !_vm.$v.form.cashbackPercentageMinimum.minValue &&
                      _vm.hasChanged.cashbackPercentageMinimum
                    ? 'field minimal value is 1'
                    : undefined},on:{"input":function () {
                    _vm.hasChanged.cashbackPercentageMinimum = true
                  }},model:{value:(_vm.form.cashbackPercentageMinimum),callback:function ($$v) {_vm.$set(_vm.form, "cashbackPercentageMinimum", $$v)},expression:"form.cashbackPercentageMinimum"}}):_vm._e(),(_vm.isCashback)?_c('VoucherTextInput',{staticClass:"w-1/2",attrs:{"fieldLabel":"Maximum Point Amount","required":true,"id":"cashback-percentage-maximum","name":"cashbackPercentageMaximum","type":"currency","placeholder":"Input number","errorMessage":!_vm.$v.form.cashbackPercentageMaximum.requiredIf &&
                  _vm.hasChanged.cashbackPercentageMaximum
                    ? 'field is required'
                    : !_vm.$v.form.cashbackPercentageMaximum.minValue &&
                      _vm.hasChanged.cashbackPercentageMaximum
                    ? 'field minimal value is 1'
                    : undefined},on:{"input":function () {
                    _vm.hasChanged.cashbackPercentageMaximum = true
                  }},model:{value:(_vm.form.cashbackPercentageMaximum),callback:function ($$v) {_vm.$set(_vm.form, "cashbackPercentageMaximum", $$v)},expression:"form.cashbackPercentageMaximum"}}):_vm._e()],1),_c('div',{staticClass:"flex flex-row w-full"},[_c('OptionBox',{ref:"select-shipmentType",staticClass:"w-1/2 mr-4",attrs:{"fieldLabel":"Shipment Type","required":true,"fieldDescription":"You can select more than 1","errorMessage":!_vm.$v.form.voucherPurposeInvoiceType.requiredIf &&
                  _vm.hasChanged.voucherPurposeInvoiceType
                    ? 'At least select one'
                    : undefined,"id":"voucher-purpose-invoice-type","name":"voucherPurposeInvoiceType","preselected":_vm.form.voucherPurposeInvoiceType,"options":_vm.constants.POINT_PURPOSES},on:{"click":_vm.setShipmentType}}),_c('OptionBox',{ref:"select-productType",staticClass:"w-1/2",attrs:{"fieldLabel":"Product Type","fieldDescription":"Currently Same Day only","id":"voucher-purpose-product-type","name":"voucherPurposeProductType","preselected":_vm.form.voucherPurposeProductType,"options":_vm.productTypeOptions},on:{"click":_vm.setProductType}})],1)])]):_vm._e(),(
            _vm.form.voucherPurposeChild &&
              _vm.form.voucherPurposeChild.value !==
                _vm.constants.PurposeType.REDEEM_PARCEL_POINT
          )?_c('div',{staticClass:"flex flex-col"},[_c('VoucherTextInput',{attrs:{"fieldLabel":"Limit usage per user","required":true,"fieldSuffix":"usages","id":"limit-usage","name":"limitUsage","type":"number","placeholder":"Input number","errorMessage":_vm.$v.form.limitUsage.$invalid && _vm.hasChanged.limitUsage
                ? 'field minimal value is 1'
                : undefined},on:{"input":function () {
                _vm.hasChanged.limitUsage = true
              }},model:{value:(_vm.form.limitUsage),callback:function ($$v) {_vm.$set(_vm.form, "limitUsage", $$v)},expression:"form.limitUsage"}})],1):_vm._e()]:_vm._e(),_c('div',[_c('div',{staticClass:"flex items-center mb-2"},[_c('Toggle',{ref:"toggleBudgetAmount",attrs:{"disabled":!(_vm.form.voucherTrigger instanceof Array) && _vm.isOnSchedule,"id":'toggleBudgetAmount',"checked":_vm.toggleBudgetAmount,"circle-height-class":"h-3","circle-width-class":"w-3","body-height-class":"h-5","body-width-class":"w-8","body-active-class":"bg-bgPrimary","body-inactive-class":"bg-gray-2","body-border-class":"border-0","circle-active-class":"bg-white","circle-inactive-class":"bg-white"},on:{"change":_vm.isToggleBudgetAmount}}),_c('span',{staticClass:"font-semibold text-sm text-bgMatterhorn"},[_vm._v("Budget Amount")])],1),_c('VoucherTextInput',{attrs:{"disabled":!_vm.toggleBudgetAmount,"fieldLabel":"","required":false,"id":"discount-amount","name":"discountAmount","type":"currency","placeholder":"Input just number","fieldDescription":_vm.toggleBudgetAmount ? 'Budget amount can’t be 0' : '',"errorMessage":_vm.errorMessageBudgetAmount},on:{"input":function () {
              _vm.hasChanged.budgetAmount = true
            }},model:{value:(_vm.form.budgetAmount),callback:function ($$v) {_vm.$set(_vm.form, "budgetAmount", $$v)},expression:"form.budgetAmount"}})],1)],2),_c('div',{staticClass:"flex flex-col w-2/5"},[_c('span',{staticClass:"text-grayInactive font-semibold text-base mt-2 mb-4"},[_vm._v("Voucher Period")]),_c('div',{staticClass:"w-full flex flex-row"},[_c('div',{staticClass:"flex flex-col mr-4 w-full"},[_c('VoucherDatePicker',{attrs:{"disabled":_vm.isOnSchedule,"min-date":_vm.todayDate,"valueFormat":"date","formatted":"DD/MM/YYYY","fieldLabel":"Publish Start Date","required":true,"id":"start-date","name":"startDate","label":"DD/MM/YYYY","auto-close":true,"errorMessage":!_vm.$v.form.startDate.requiredIf && _vm.hasChanged.startDate
                ? 'field is required'
                : undefined},on:{"input":function () {
                _vm.hasChanged.startDate = true
              }},model:{value:(_vm.form.startDate),callback:function ($$v) {_vm.$set(_vm.form, "startDate", $$v)},expression:"form.startDate"}}),_c('VoucherDatePicker',{attrs:{"disabled":_vm.isOnSchedule,"min-date":_vm.form.startDate,"valueFormat":"date","formatted":"DD/MM/YYYY","fieldLabel":"Publish End Date","required":true,"id":"end-date","name":"endDate","label":"DD/MM/YYYY","auto-close":true,"errorMessage":!_vm.$v.form.endDate.requiredIf && _vm.hasChanged.endDate
                ? 'field is required'
                : undefined},on:{"input":function () {
                _vm.hasChanged.endDate = true
              }},model:{value:(_vm.form.endDate),callback:function ($$v) {_vm.$set(_vm.form, "endDate", $$v)},expression:"form.endDate"}})],1),_c('div',{staticClass:"flex flex-col w-full"},[_c('VoucherDatePicker',{attrs:{"disabled":_vm.isOnSchedule,"valueFormat":"time","formatted":"HH:mm:ss","fieldLabel":"Publish Start Time","required":true,"id":"start-time","name":"startTime","label":"HH:MM:SS","errorMessage":!_vm.$v.form.startTime.requiredIf && _vm.hasChanged.startTime
                ? 'field is required'
                : undefined},on:{"input":function () {
                _vm.hasChanged.startTime = true
              }},model:{value:(_vm.form.startTime),callback:function ($$v) {_vm.$set(_vm.form, "startTime", $$v)},expression:"form.startTime"}}),_c('VoucherDatePicker',{attrs:{"disabled":_vm.isOnSchedule,"valueFormat":"time","formatted":"HH:mm:ss","fieldLabel":"Publish End Time","required":true,"id":"end-time","name":"endTime","label":"HH:MM:SS","errorMessage":!_vm.$v.form.endTime.requiredIf && _vm.hasChanged.endTime
                ? 'field is required'
                : undefined},on:{"input":function () {
                _vm.hasChanged.endTime = true
              }},model:{value:(_vm.form.endTime),callback:function ($$v) {_vm.$set(_vm.form, "endTime", $$v)},expression:"form.endTime"}})],1)]),(_vm.form.voucherPurposeChild.value)?_c('div',{staticClass:"flex flex-row w-full items-center"},[_c('div',{staticClass:"w-full mr-4"},[_c('VoucherDatePicker',{attrs:{"min-date":_vm.todayDate,"formatted":"DD/MM/YYYY","fieldLabel":"Voucher Expiration Date","required":true,"id":"expiration-date","name":"expirationDate","label":"DD/MM/YYYY","errorMessage":!_vm.$v.form.voucherExpiryDate && _vm.hasChanged.voucherExpiryDate
                ? 'field is required'
                : undefined},on:{"input":function () {
                _vm.hasChanged.voucherExpiryDate = true
              }},model:{value:(_vm.form.voucherExpiryDate),callback:function ($$v) {_vm.$set(_vm.form, "voucherExpiryDate", $$v)},expression:"form.voucherExpiryDate"}})],1),_c('div',{staticClass:"w-full text-bgMatterhorn"},[_vm._v("at 23:59:59 WIB")])]):_vm._e()])]),_c('div',{staticClass:"flex flex-row justify-center mt-10"},[_c('router-link',{attrs:{"to":".."}},[_c('Button',{staticClass:"px-12 py-3 mr-4 text-sm",attrs:{"buttonStyle":"outline","customPadding":""}},[_vm._v("Cancel ")])],1),_c('Button',{ref:"button-publish",staticClass:"px-12 py-3 text-sm",attrs:{"customPadding":"","disabled":_vm.$v.form.$invalid},on:{"click":function () {
          _vm.confirmationModal = true
        }}},[_vm._v("Publish ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }